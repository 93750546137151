import React, { useEffect, useState } from 'react'
import { Offcanvas, Row, Col, CloseButton } from 'react-bootstrap'
import { RootState } from 'redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { closeNewAppointment, setNewAppointmentDate, setNewAppointmentRoom } from 'redux/stateSlice'
import { addLoadedAppointment } from 'redux/calendarSlice'
import { Formik, FormikHelpers } from 'formik'
import useApi from 'hooks/useApi'
import useToast from 'hooks/useToast'
import AvailabilityGrid from 'components/AvailabilityGrid'
import useAvailability from 'hooks/useAvailability'
import { loadAvailability as _loadAvailability } from 'hooks/useAvailability'
import useHelpers from 'hooks/useHelpers'
import AppointmentSummary from 'components/AppointmentSummary'
import AppointmentPatients from 'components/AppointmentPatients'
import appointmentCreateSchema from 'schemas/appointment-create-schema'
import { AppointmentSchemaType } from 'schemas/appointment-schema'
import { add15MinsToDate, getNextFreeSlotFromDate } from 'helpers/date'
import NewPatientConfirmationModal from 'components/NewPatientConfirmationModal'
import moment from 'moment'
import useData from 'hooks/useData'

/**
 * Edit appointments offcanvas
 */
const NewAppointment: React.FC = () => {
  // State
  const { open, newAppointmentDate, newAppointmentRoom } = useSelector((state: RootState) => ({
    open: state.state.newAppointmentOpen,
    newAppointmentDate: state.state.newAppointmentDate as string,
    newAppointmentRoom: state.state.newAppointmentRoom as string,
  }))

  const dispatch = useDispatch()
  const api = useApi()
  const toast = useToast()
  const helpers = useHelpers()

  const data = useData()

  const { isCalculating, timeSlots, availability, loadAvailability } = useAvailability(newAppointmentDate)

  const initialValues: AppointmentSchemaType = {
    intent: 'create',
    title: 'New appointment',
    selections: [
      {
        startDate: newAppointmentDate,
        endDate: add15MinsToDate(new Date(newAppointmentDate)).toISOString(),
        roomId: newAppointmentRoom,
      },
    ],
    statusId: '1',
    typeId: '3',
    existingPatients: [],
    newPatients: [],
    notes: [],
  }

  const [hasAcknowledgedConfirmation, setHasAcknowledgedConfirmation] = useState<true | false | null>(null)

  // TODO: reuse this on new appointments offcanvas
  const handleSubmit = async (values: typeof initialValues, formikHelpers: FormikHelpers<typeof initialValues>) => {
    const { setSubmitting, resetForm } = formikHelpers

    if (values.newPatients.length > 0 && !hasAcknowledgedConfirmation) {
      let areAnyNewPatientsTravelling = false
      values.newPatients.forEach(newPatient => {
        if (newPatient.isTravelling && newPatient.sendRegistrationEmail) {
          areAnyNewPatientsTravelling = true
        }
      })

      if (areAnyNewPatientsTravelling) {
        setHasAcknowledgedConfirmation(false)
        return
      }
    }

    if (values) {
      const submission = await api.appointments.book(helpers.appointments.formatCreateSchemaForRequest(values))
      if (submission.errors) {
        toast.error({
          title: 'Error booking appointments',
          text: submission.errors[0].body,
        })
        return
      }

      // 1. Reset appointment info
      resetForm({ values: { ...initialValues } })
      dispatch(closeNewAppointment())

      const successfulAppointments: Appointment[] = []
      const failedAppointments: FailedAppointment[] = []
      // 2. Update calendar appointments with new Appointment
      submission.data.forEach(appointment => {
        // Discriminating successful appointments
        if ('title' in appointment) {
          if (helpers.appointments.isInLoadedRange(appointment.startDate, appointment.endDate)) {
            dispatch(addLoadedAppointment(appointment))
          }
          successfulAppointments.push(appointment)
          // Failed appointments
        } else {
          // todo: make this dispatchable so that failed appointments are then reflected on the calendar
          // either by dispatching as seen below, or modifying AppointmentsProvider to directly expose loadData() functionality
          // dispatch(addLoadedAppointment(appointment))
          failedAppointments.push(appointment)
        }
      })

      let toastSuccessMessage: string = ''
      if (successfulAppointments.length === 1) {
        toastSuccessMessage = `Successfully booked a new appointment for ${new Date(
          successfulAppointments[0].startDate
        ).toDateString()}`
      } else if (successfulAppointments.length > 1) {
        const successfulAppointmentMessages = successfulAppointments.map(appointment => {
          const time = `${new Date(appointment.startDate).toLocaleTimeString('en-gb', {
            hour: '2-digit',
            minute: '2-digit',
          })} - ${new Date(appointment.endDate).toLocaleTimeString('en-gb', {
            hour: '2-digit',
            minute: '2-digit',
          })}`
          return `${new Date(appointment.startDate).toDateString()} at ${time} in ${appointment.room.title} room`
        })
        toastSuccessMessage = `Successfully booked new appointments for: ${successfulAppointmentMessages.join(', ')}`
      }

      if (toastSuccessMessage) {
        toast.success({
          title: 'Appointments',
          text: toastSuccessMessage,
          timestamp: true,
        })
      }

      failedAppointments.forEach(failedAppointment => {
        if (failedAppointment.startDate.length > 0 && failedAppointment.endDate.length > 0) {
          toast.error({
            title: 'Error booking appointment',
            text: `${failedAppointment.startDate[0].message}: ${moment(failedAppointment.startDate[0].data)
              .local()
              .format('DD/MM/YYYY HH:mm:ss')}. ${failedAppointment.endDate[0].message} ${moment(
              failedAppointment.endDate[0].data
            )
              .local()
              .format('DD-MM-YYYY HH:mm:ss')}!`,
            timestamp: true,
            persist: true,
          })
        } else if (failedAppointment.startDate.length > 0) {
          toast.error({
            title: 'Error booking appointment',
            text: `${failedAppointment.startDate[0].message}: ${moment(failedAppointment.startDate[0].data)
              .local()
              .format('DD/MM/YYYY HH:mm:ss')}!`,
            timestamp: true,
            persist: true,
          })
        } else if (failedAppointment.endDate.length > 0) {
          toast.error({
            title: 'Error booking appointment',
            text: `${failedAppointment.endDate[0].message} ${moment(failedAppointment.endDate[0].data)
              .local()
              .format('DD-MM-YYYY HH:mm:ss')}!`,
            timestamp: true,
            persist: true,
          })
        }
      })

      setSubmitting(false)
    }
  }

  // Fetch availability when the user opens the offcanvas
  useEffect(() => {
    if (open && newAppointmentDate) {
      loadAvailability()
    }
  }, [open, newAppointmentDate])

  return (
    <Offcanvas
      show={open}
      onHide={() => {
        dispatch(closeNewAppointment())
      }}
      onExited={() => dispatch(setNewAppointmentDate(null))}
      placement="end"
      backdropClassName="new-appointment-backdrop"
      className="new-appointment-offcanvas"
    >
      <Offcanvas.Header className="border-bottom border-2">
        <h3 className="mb-0">New Appointment</h3>
        <CloseButton onClick={() => dispatch(closeNewAppointment())} />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={handleSubmit}
          validationSchema={appointmentCreateSchema}
          validateOnMount={true}
          enableReinitialize={true}
        >
          {() => {
            return (
              <>
                <Row>
                  <Col xs={12}>
                    <AppointmentPatients setHasAcknowledgedConfirmation={setHasAcknowledgedConfirmation} />
                  </Col>
                </Row>
                <hr className="my-5" />
                <Row>
                  <AvailabilityGrid
                    timeSlots={timeSlots}
                    availability={availability}
                    isCalculating={isCalculating}
                    onDateChange={val => {
                      if (!val) {
                        val = new Date().toISOString()
                      }
                      // Find
                      const findFirstAvailableSlotOnSelectedDate = async () => {
                        const rooms = data.rooms.all()

                        let selectedDate = new Date(val)
                        const selectedDateMoment = moment(getNextFreeSlotFromDate(selectedDate))
                        const day = selectedDateMoment.format('YYYY-MM-DD')
                        const dayEnd = selectedDateMoment.clone().add(1, 'day').format('YYYY-MM-DD')

                        const appointments = await api.appointments.range(day, dayEnd) // all appointments for the day
                        const roomAvailabilities = await api.roomAvailability.range(day, day) // all available rooms for the day

                        let availability = await _loadAvailability(
                          selectedDateMoment,
                          appointments.data ?? [],
                          roomAvailabilities.data ?? [],
                          rooms
                        )

                        let isAnyRoomAvailable = false
                        availability.forEach(room => {
                          if (room.slots.length > 0) {
                            isAnyRoomAvailable = true
                          }
                        })
                        if (!isAnyRoomAvailable) {
                          toast.error({ text: `No free slots for ${val}.`, title: 'Date unavailable!' })
                          return
                        }

                        let allAvailableSlots: {
                          roomId: string
                          date: Date
                          slot: { date: Date; durations: number[] }
                        }[] = []

                        for (const room of availability) {
                          for (const slot of room.slots) {
                            if (slot.durations.length > 0) {
                              allAvailableSlots.push({
                                roomId: room.roomId.toString(),
                                date: slot.date,
                                slot: slot,
                              })
                            }
                          }
                        }

                        // If we have available slots, select the first one
                        if (allAvailableSlots.length > 0) {
                          const firstAvailableSlot = allAvailableSlots[0]
                          dispatch(setNewAppointmentRoom(firstAvailableSlot.roomId))
                          dispatch(setNewAppointmentDate(firstAvailableSlot.date.toISOString()))
                        } else {
                          toast.error({ text: `No free slots for ${val}.`, title: 'Date unavailable!' })
                        }
                      }

                      findFirstAvailableSlotOnSelectedDate()
                    }}
                    alwaysActive
                  />
                </Row>
                <hr className="my-5" />
                <Row>
                  <Col>
                    <AppointmentSummary />
                  </Col>
                </Row>
                <NewPatientConfirmationModal
                  hasAcknowledgedConfirmation={hasAcknowledgedConfirmation}
                  setHasAcknowledgedConfirmation={setHasAcknowledgedConfirmation}
                />
              </>
            )
          }}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default NewAppointment
