import { Fragment, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Field, useFormikContext } from 'formik'
import TextArea from 'components/TextArea'
import { createColumnHelper } from '@tanstack/react-table'
import FunctionalTable from 'components/FunctionalTable'
import RemoveButton from 'components/RemoveButton'
import useCanEdit from 'hooks/useCanEdit'
import { PatientInfoSchemaType } from 'schemas/patient-info-schema'
import ErrorMessage from 'components/ErrorMessage'

const travelHealthOptions = [
  { key: 'bite_avoidance', label: 'Bite avoidance' },
  { key: 'emailed_info', label: 'Emailed info' },
  { key: 'food_water_hygiene', label: 'Food & water' },
  { key: 'infectious_diseases', label: 'Infectious diseases' },
  { key: 'insurance', label: 'Insurance' },
  { key: 'personal_safety', label: 'Personal Safety' },
  { key: 'side_effects', label: 'Side effects' },
  { key: 'sun_environment', label: 'Sun & environment' },
]

const NotesTable = ({ handleRemove, isEditing }) => {
  const { values, isSubmitting } = useFormikContext<Patient>()

  const canEdit = useCanEdit()

  const tableData = useMemo(
    () =>
      [...values.notes].map(note => ({
        dateCreated: new Date(note.dateCreated),
        note: note.note,
        addedBy: {
          id: note.author.id,
          title: `${note.author.firstName || ''} ${note.author.lastName || ''}`.trim() || note.author.friendlyName,
        },
        travelHealthInfo: note.travelHealthInfo,
      })),
    [values]
  )

  const columnHelper = createColumnHelper<(typeof tableData)[number]>()

  const columns = useMemo(
    () => [
      columnHelper.accessor('note', {
        header: 'Note',
        cell: info => {
          return <NoteInput isDisabled={!isEditing || !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) || isSubmitting} rowIndex={info.row.index} />
        },
        meta: {
          style: {
            width: '99%',
          },
        },
        enableSorting: false,
      }),
      columnHelper.accessor('travelHealthInfo', {
        header: 'Advice Given',
        cell: info => {
          return (
            <AdviceGivenCheckbox rowIndex={info.row.index} isDisabled={!isEditing || !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) || isSubmitting} />
          )
        },
      }),
      columnHelper.accessor('dateCreated', {
        header: 'Date created',
        cell: info => {
          return (
            <Field
              as={Form.Control}
              type="datetime-local"
              step="1"
              name={`notes[${info.row.index}].dateCreated`}
              disabled
            />
          )
        },
      }),
      columnHelper.accessor('addedBy', {
        header: 'Created by',
        cell: info => {
          return <Form.Control
            readOnly
            value={info.getValue().title}
            disabled
            style={{ minWidth: 150 }} />
        },
      }),
      columnHelper.display({
        id: 'remove',
        header: '',
        cell: info =>
          isEditing &&
          canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) &&
          (
            <RemoveButton
              type="button"
              disabled={isSubmitting}
              onClick={() => handleRemove(info.row.index)} />
          ),
      }),
    ],
    [values.notes.length, isEditing, isSubmitting]
  )

  return (
    <FunctionalTable
      data={tableData}
      columns={columns}
      options={{ className: 'notes-table' }}
      sortingState={[{ id: 'dateCreated', desc: false }]}
    />
  )
}

export default NotesTable

const NoteInput = ({ rowIndex, isDisabled }: TabularInputProps) => {
  const { errors} = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.notes && typedErrors.notes.length > 0 ? typedErrors.notes[rowIndex] : undefined
  return (
    <>
      <Field 
        disabled={isDisabled}
        as={TextArea}
        name={`notes[${rowIndex}].note`} 
        rows={8}
        isInvalid={Boolean(error)} />
      <Form.Control.Feedback type="invalid">{error?.newInformation}</Form.Control.Feedback>
    </>
  )
}

const AdviceGivenCheckbox = ({rowIndex, isDisabled}: TabularInputProps) => {
  const { values, errors, setFieldValue } = useFormikContext<Patient>()
  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])
  const error = typedErrors.notes && typedErrors.notes.length > 0 ? typedErrors.notes[rowIndex] : undefined

  return (
    <div style={{ width: '175px' }}>
      {travelHealthOptions.map(option => {
        const checked = Boolean(values.notes[rowIndex].travelHealthInfo[option.key])
        return (
          <Fragment key={option.key}>

          <Form.Check
            key={option.key}
            disabled={isDisabled}
            id={`notes:${rowIndex}:travelHealthInfo:${option.key}`}
            checked={checked}
            onChange={e => {
              setFieldValue(`notes[${rowIndex}].travelHealthInfo[${option.key}]`, Number(!checked))
            }}
            label={option.label}
          />
            </Fragment>
        )
      })}
      <ErrorMessage error={error?.newInformation} />
    </div>
  )
}
