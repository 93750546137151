import { createColumnHelper } from '@tanstack/react-table'
import FunctionalTable from 'components/FunctionalTable'
import { Field, useFormikContext } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import useData from 'hooks/useData'
import RemoveButton from 'components/RemoveButton'
import moment from 'moment'
import { type PatientInfoSchemaType } from 'schemas/patient-info-schema'

/**
 * The table component inside the Travel tab
 */
const TravelTable = ({ tripIndex, isEditing, handleAdd, handleChange, handleRemove }) => {
  const { values, isSubmitting } = useFormikContext<Patient>()
  const data = useData()

  interface TableData {
    destination: string
    accommodationStyle: string
    arrivalDate: Date
    returnDate: Date | null
    duration: string | null
  }

  const tableData: TableData[] = useMemo(
    () =>
      values.trips[tripIndex].journeys.map(journey => ({
        destination: data.destinations.one(journey.destination.id)?.title || '',
        accommodationStyle: data.accommodations.one(journey.accommodation.id)?.title || '',
        arrivalDate: new Date(journey.arrivalDate),
        returnDate: journey.returnDate ? new Date(journey.returnDate) : null,
        duration: journey.duration ? data.journeyDurations.one(journey.duration.id)?.title || null : null
      })),
    [tripIndex, values.trips[tripIndex]]
  )

  const columnHelper = createColumnHelper<TableData>()

  const [journeyDurationTypeMap, setJourneyDurationTypeMap] = useState<Map<number, 'returnDate' | 'lengthOfStay'>>(new Map())

  const handleUpdateJourneyDurationType = useCallback((rowIndex: number, journeyDurationType: 'returnDate' | 'lengthOfStay' = 'lengthOfStay') => {
    const row = tableData[rowIndex]
    if (row && !journeyDurationTypeMap.has(rowIndex)) {
      if (row.returnDate) {
        journeyDurationType = 'returnDate'
      } else {
        journeyDurationType = 'lengthOfStay'
      }
    }

    setJourneyDurationTypeMap((prev) => new Map(prev).set(rowIndex, journeyDurationType))
  }, [journeyDurationTypeMap])

  const tableColumns = useMemo(
    () => [
      columnHelper.accessor('destination', {
        header: 'Destination',
        cell: info => (<JourneyDestinationInput rowIndex={info.row.index} isDisabled={!isEditing || isSubmitting} tripIndex={tripIndex} />),
      }),
      columnHelper.accessor('accommodationStyle', {
        header: 'Accommodation style',
        cell: info => (<JourneyAccommodationStyleInput rowIndex={info.row.index} isDisabled={!isEditing || isSubmitting} tripIndex={tripIndex}/>),
      }),
      columnHelper.accessor('arrivalDate', {
        header: 'Arrival date',
        cell: info => (<JourneyArrivalDateInput rowIndex={info.row.index} isDisabled={!isEditing || isSubmitting} tripIndex={tripIndex} />),
      }),
      columnHelper.accessor('duration', {
        header: 'Length of Stay',
        cell: info => (<JourneyLengthOfStayInput rowIndex={info.row.index} isDisabled={!isEditing || isSubmitting} tripIndex={tripIndex} handleUpdateJourneyDurationType={handleUpdateJourneyDurationType} journeyDurationTypeMap={journeyDurationTypeMap} />),
      }),
      columnHelper.accessor('returnDate', {
        header: 'Return date',
        cell: info => (
            <JourneyReturnDateInput rowIndex={info.row.index} isDisabled={!isEditing || isSubmitting} tripIndex={tripIndex} handleUpdateJourneyDurationType={handleUpdateJourneyDurationType} journeyDurationTypeMap={journeyDurationTypeMap}/>
        ),
      }),
      columnHelper.display({
        id: 'remove',
        header: '',
        cell: info => (
          <RemoveButton disabled={!isEditing || isSubmitting} onClick={() => handleRemove(info.row.index)} />
        ),
      }),
    ],
    [!isEditing || isSubmitting, isEditing, tripIndex, journeyDurationTypeMap, handleUpdateJourneyDurationType]
  )

  return (
    <div>
      <FunctionalTable data={tableData} columns={tableColumns} />
    </div>
  )
}

type TabularTripInputProps = TabularInputProps & {
  tripIndex: number
}

const JourneyDestinationInput = ({rowIndex, isDisabled, tripIndex}: TabularTripInputProps) => {
  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const tripErrors = typedErrors.trips && typedErrors.trips.length > 0 ? typedErrors.trips[tripIndex] : undefined

  const error = tripErrors && tripErrors.journeys && tripErrors.journeys.length > 0 ? tripErrors.journeys[rowIndex]?.destination?.id : undefined

  const data = useData()

  const destinations = data.destinations.all()

  return (
    <>
      <Field
        disabled={isDisabled}
        as={Form.Select}
        name={`trips[${tripIndex}].journeys[${rowIndex}].destination.id`}
        isInvalid={Boolean(error)}
      >
        <option value="default" selected disabled>
          Please select
        </option>
        {destinations.map(destination => (
          <option key={destination.id} value={destination.id}>
            {destination.title}
          </option>
        ))}
      </Field>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const JourneyAccommodationStyleInput = ({rowIndex, isDisabled, tripIndex}: TabularTripInputProps) => {
  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const tripErrors = typedErrors.trips && typedErrors.trips.length > 0 ? typedErrors.trips[tripIndex] : undefined

  const error = tripErrors && tripErrors.journeys && tripErrors.journeys.length > 0 ? tripErrors.journeys[rowIndex]?.accommodation?.id : undefined

  const data = useData()

  const accommodations = data.accommodations.all()

  return (
    <>
      <Field
        disabled={isDisabled}
        as={Form.Select}
        name={`trips[${tripIndex}].journeys[${rowIndex}].accommodation.id`}
        isInvalid={Boolean(error)}
      >
        <option value="default" selected disabled>
          Please select
        </option>
        {accommodations.map(accommodation => (
          <option key={accommodation.id} value={accommodation.id}>
            {accommodation.title}
          </option>
        ))}
      </Field>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const JourneyArrivalDateInput = ({rowIndex, isDisabled, tripIndex}: TabularTripInputProps) => {
  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const tripErrors = typedErrors.trips && typedErrors.trips.length > 0 ? typedErrors.trips[tripIndex] : undefined

  const error = tripErrors && tripErrors.journeys && tripErrors.journeys.length > 0 ? tripErrors.journeys[rowIndex]?.arrivalDate : undefined

  return (
    <>
      <Field
        name={`trips[${tripIndex}].journeys[${rowIndex}].arrivalDate`}
        isInvalid={Boolean(error)}
        disabled={isDisabled}
        type="date"
        as={Form.Control}
      >
      </Field>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

type JourneyDurationTabularTripInputProps = TabularTripInputProps & {
  handleUpdateJourneyDurationType: (rowIndex: number, journeyDurationType?: 'returnDate' | 'lengthOfStay') => void,
  journeyDurationTypeMap: Map<number, "returnDate" | "lengthOfStay">
}

const JourneyLengthOfStayInput = ({ rowIndex, isDisabled, tripIndex, handleUpdateJourneyDurationType, journeyDurationTypeMap }: JourneyDurationTabularTripInputProps) => {
  useEffect(() => {
    if (journeyDurationTypeMap.has(rowIndex)) {
      return
    }
    // initialise if not already there. Omitting the journey duration type parameter to let it use the default as defined on the function
    handleUpdateJourneyDurationType(rowIndex)
  }, [journeyDurationTypeMap, rowIndex])
  
  const { errors, setFieldValue } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const tripErrors = typedErrors.trips && typedErrors.trips.length > 0 ? typedErrors.trips[tripIndex] : undefined

  const error = tripErrors && tripErrors.journeys && tripErrors.journeys.length > 0 ? tripErrors.journeys[rowIndex]?.duration as string : undefined

  const handleUseLengthOfStay = (tripIndex, journeyIndex) => {
    setFieldValue(`trips[${tripIndex}].journeys[${journeyIndex}].duration`, null)
    setFieldValue(`trips[${tripIndex}].journeys[${journeyIndex}].returnDate`, null)
    handleUpdateJourneyDurationType(rowIndex, 'lengthOfStay')
  }

  const data = useData()
  const durations = data.journeyDurations.all()

  return (
    <>
      <div className={journeyDurationTypeMap.get(rowIndex) === 'lengthOfStay' ? '' : 'd-none'} >
        <Field
          disabled={isDisabled}
          className={journeyDurationTypeMap.get(rowIndex) === 'lengthOfStay' ? '' : 'd-none'}
          as={Form.Select}
          name={`trips[${tripIndex}].journeys[${rowIndex}].duration.id`}
          isInvalid={Boolean(error)}
          >
          <option value="default" selected disabled>
            Please select
          </option>
          {durations.map(duration => (
            <option key={duration.id} value={duration.id}>
              {duration.title}
            </option>
          ))}
        </Field>
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </div>
      <Button
        variant="secondary"
        className={journeyDurationTypeMap.get(rowIndex) === 'returnDate' ? 'w-100' : 'd-none'}
        disabled={isDisabled}
        onClick={() => handleUseLengthOfStay(tripIndex, rowIndex)}
        >
          Use Length of Stay
      </Button>
    </>
  )
}

const JourneyReturnDateInput = ({rowIndex, isDisabled, tripIndex, handleUpdateJourneyDurationType, journeyDurationTypeMap}: JourneyDurationTabularTripInputProps) => {
  useEffect(() => {
    if (journeyDurationTypeMap.has(rowIndex)) {
      return
    }
    // initialise if not already there. Omitting the journey duration type parameter to let it use the default as defined on the function
    handleUpdateJourneyDurationType(rowIndex)
  }, [journeyDurationTypeMap, rowIndex])

  const { errors, setFieldValue } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const tripErrors = typedErrors.trips && typedErrors.trips.length > 0 ? typedErrors.trips[tripIndex] : undefined

  const error = tripErrors && tripErrors.journeys && tripErrors.journeys.length > 0 ? tripErrors.journeys[rowIndex]?.returnDate : undefined

  const handleUseReturnDate = (tripIndex, journeyIndex) => {
    setFieldValue(`trips[${tripIndex}].journeys[${journeyIndex}].duration`, null)
    setFieldValue(`trips[${tripIndex}].journeys[${journeyIndex}].returnDate`, moment().format('YYYY-MM-DD'))
    handleUpdateJourneyDurationType(rowIndex, 'returnDate')
  }

  return (
    <>
      <div className={journeyDurationTypeMap.get(rowIndex) === 'returnDate' ? '' : 'd-none'} >
        <Field
          name={`trips[${tripIndex}].journeys[${rowIndex}].returnDate`}
          isInvalid={Boolean(error)}
          disabled={isDisabled}
          type="date"
          as={Form.Control}
          >
        </Field>
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </div>
      <Button
        variant="secondary"
        className={journeyDurationTypeMap.get(rowIndex) === 'lengthOfStay' ? 'w-100' : 'd-none'}
        disabled={isDisabled}
        onClick={() => handleUseReturnDate(tripIndex, rowIndex)}
      >
        Use Return Date
      </Button>
    </>
  )
  
}


export default TravelTable
